<template>
  <div class="container">
    <Header></Header>
    <div class="details-box">
      <div class="title">
        <div class="icon">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="caption">
          标题标题标题标题标题标题标标题标题标 题标题标题标题标题标题标题
        </div>
      </div>
      <div class="content">
        <div class="category">归类：体系搭建</div>
        <div class="text">培训服务</div>
        <p>
           我们致力于为您提供更优质的培训课程，助力您提升管理技能、拓展业务领域。我们的课程涵盖了数字化转型、管理体系建设、业务流程优化。
        </p>
        <div class="chunk">
          <span></span>
          <span>培训课程：</span>
        </div>
        <p>
          我们致力于为您提供更优质的培训课程，助力您提升管理技能、拓展业务领域。我们的课程涵盖了数字化转型、管理体系建设、业务流程优化、
          质量零缺陷管理等多个方面，为你提供全方位的培训支持。通过我们的专业指导和实践操作，您将掌握先进的数字化转型策略和技巧，轻松应对市场挑战，
          实现销售业绩的持续增长。无论您是初学者还是资深管理人员，我们都有适合您的课程，助您取得更大的成功！
        </p>
        <div class="flow">
          <div class="flow-item" v-for="(item,index) in flow" :key="index">
            <span></span>
            <span>{{item.text}}</span>
          </div>
        </div>
         <div class="chunk" style="margin-top:0.4rem">
          <span></span>
          <span>培训课程：</span>
        </div>
        <p>
          我们致力于为您提供更优质的培训课程，助力您提升管理技能、拓展业务领域。我们的课程涵盖了数字化转型、管理体系建设、业务流程优化、
          质量零缺陷管理等多个方面，为你提供全方位的培训支持。通过我们的专业指导和实践操作，您将掌握先进的数字化转型策略和技巧，轻松应对市场挑战，
          实现销售业绩的持续增长。无论您是初学者还是资深管理人员，我们都有适合您的课程，助您取得更大的成功！
        </p>
        <div class="img"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/mobile/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      flow:[
        {
          text:"数字化体系建设"
        },
         {
          text:"业务流程优化"
        },
         {
          text:"绩效驱动轮管理模式"
        },
         {
          text:"绩效标准制定"
        },
         {
          text:"价值链分析"
        },
         {
          text:"质量零缺陷"
        },
         {
          text:"6S现场管理"
        },
         {
          text:"供应商管理"
        },
         {
          text:"质量五大工具"
        },
         {
          text:"知识管理"
        },
         {
          text:"商业机密保护"
        },
         {
          text:"班组长提升"
        },
      ]
    };
  },
};
</script>

<style lang="less" scoped>
.container{
    min-height: 100vh;
    background-color: #ffffff;
    .details-box{
        padding: 0 .3rem .88rem;
        box-sizing: border-box;
        .title{
          width: 100%;
          text-align: center;
          padding: .58rem 0 .48rem;
          border-bottom: 1px solid #c9c9c9;
          display: flex;
          .icon{
            width: .39rem;
            margin-left: .16rem;
            div{
              height: .04rem;
              background-color: rgba(38, 59, 81, 1);
            }
            div:nth-child(2){
              margin: .1rem 0;
            }
          }
          .caption{
            width: 5.74rem;
            font-size: 0.32rem;
            font-weight: bold;
            color: #000000;
            line-height: 0.32rem;
            margin-left: .24rem;
          }
        }
        .content{
          margin-top: .32rem;
          .category{
            height: .26rem;
            font-size: .26rem;
            font-weight: 400;
            color: rgba(81, 81, 81, 0.7);
            line-height: .26rem;
          }
          .text{
            height: 0.3rem;
            font-size: 0.3rem;
            font-weight: bold;
            color: #000000;
            line-height: 0.3rem;
            margin-top: .35rem;
          }
          p{
            text-indent: 2em;
            font-size: 0.28rem;
            font-weight: 500;
            color: #555555;
            line-height: 0.48rem;
          }
          .chunk{
            margin-top: .76rem;
            span:nth-child(1){
              display: inline-block;
              width: 0.05rem;
              height: 0.36rem;
              background: #3699FF;
              border-radius: 0.03rem;
              vertical-align: middle;
            }
            span:nth-child(2){
              font-size: 0.3rem;
              font-weight: bold;
              color: #000000;
              margin-left: .16rem;
              vertical-align: middle;
            }
          }
          .flow{
            margin-top: .24rem;
            display: flex;
            flex-wrap: wrap;
            .flow-item{
              margin-left: .3rem;
              margin-bottom: .3rem;
              width: calc(50% - .3rem);
              span:nth-child(1){
                display: inline-block;
                width: .2rem;
                height: .2rem;
                border-radius: 50%;
                background-color: RGBA(45, 123, 192, 1);
              }
              span:nth-child(2){
                font-size: .28rem;
                color: RGBA(77, 67, 62, 1);
                margin-left: .12rem;
              }
            }
          }
          .img{
            margin-top: .4rem;
            width: 100%;
            height: 4.64rem;
            background: url("../../assets/mobileImgs/bg2.png") no-repeat;
            background-size:100% 100%;
          }
        }
    }
}
</style>